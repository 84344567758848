import layout from '@/layout'
export default {
  path: '/crowdsourcing',
  component: layout,
  name: 'crowdsourcing',
  meta: {
    title: '众包',
    icon: 'crowdsourcing'
  },
  redirect: '/talent/list',
  children: [
    {
      path: '/talent/list',
      component: () => import('@/views/crowdsourcing/talent/index'),
      name: 'talent-list',
      meta: {
        title: '人才管理',
        icon: 'talent'
      }
    },
    {
      path: '/talent/category',
      component: () => import('@/views/crowdsourcing/talent/category'),
      name: 'talent-category',
      meta: {
        title: '人才分类',
        icon: 'talent'
      }
    },
    {
      path: '/project/list',
      component: () => import('@/views/crowdsourcing/project/index'),
      name: 'project-list',
      meta: {
        title: '项目共享',
        icon: 'project'
      }
    },
    {
      path: '/order/list',
      component: () => import('@/views/crowdsourcing/order/index'),
      name: 'order-list',
      meta: {
        title: '派单管理',
        icon: 'order'
      }
    },
    {
      path: '/group/list',
      component: () => import('@/views/crowdsourcing/group/index'),
      name: 'apply-group',
      meta: {
        title: '加群管理',
        icon: 'group'
      }
    },
    {
      path: '/group/category',
      component: () => import('@/views/crowdsourcing/group/category.vue'),
      name: 'group-category',
      meta: {
        title: '群分类管理',
        icon: 'group-category'
      }
    }
  ]
}
